<template>
  <div>
    <b-card
      no-body
      class="card-custom card-stretch gutter-b"
      v-if="latestBreaches.length"
    >
      <div class="card-header border-0">
        <h3 v-if="table" class="card-title font-weight-bolder text-dark">
          {{ $tc("dashboard.lastBreaches.department.title") }}
        </h3>
        <h3 v-else class="card-title font-weight-bolder text-dark">
          {{ $tc("dashboard.lastBreaches.users.title") }}
        </h3>
        <div class="card-toolbar">
          <div class="d-flex justify-content-center mt-4">
            <b-button
              class="font-weight-bolder mr-2"
              :variant="table ? 'primary' : 'outline-secondary'"
              pill
              @click="table = true"
            >
              {{ $tc("dashboard.lastBreaches.company.btn") }}
            </b-button>
            <b-button
              class="font-weight-bolder"
              :variant="!table ? 'primary' : 'outline-secondary'"
              pill
              @click="table = false"
            >
              {{ $tc("dashboard.lastBreaches.users.btn") }}
            </b-button>
          </div>
        </div>
      </div>

      <!--begin::Body-->
      <div class="card-body pt-0">
        <latest-breaches-department-component
          v-if="table"
          :data="latestBreaches"
        ></latest-breaches-department-component>
        <latest-breaches-users-department-component
          v-else
          :data="usersBreaches"
        ></latest-breaches-users-department-component>
      </div>
    </b-card>
    <empty-card v-else type="department.abs" />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { getStakeholderBreaches } from "@/api/controller/breaches.api";
import { getUsers } from "@/api/controller/stakeholders.api";
import { getUsersBreaches } from "@/utils/helpers";

import EmptyCard from "@/components/EmptyCard.component";
import LatestBreachesDepartmentComponent from "./LatestBreachesDepartment.component.vue";
import LatestBreachesUsersDepartmentComponent from "./LatestBreachesUsersDepartment.component.vue";

export default {
  name: "LatestBreachesOfDepartment",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      table: true,
      departmentBreaches: [],
      breaches: [],
      usersBreaches: []
    };
  },
  components: {
    EmptyCard,
    LatestBreachesDepartmentComponent,
    LatestBreachesUsersDepartmentComponent
  },
  computed: {
    latestBreaches() {
      return _.orderBy(
        this.departmentBreaches,
        [breach => breach.breach.breachDate],
        ["desc"]
      );
    }
  },
  mounted() {
    axios
      .all([
        getUsers("departments", this.$route.params.id),
        getStakeholderBreaches(this.$route.params.id, "departments")
      ])
      .then(
        axios.spread((usersResp, breachesResp) => {
          this.departmentBreaches = breachesResp.data.records;

          this.usersBreaches = getUsersBreaches(
            breachesResp.data.records,
            usersResp.data.records
          );
        })
      );
  }
};
</script>

<style>
.latest-breaches
  .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.latest-breaches .btn.btn-primary:focus:not(.btn-text),
.latest-breaches .btn.btn-primary.focus:not(.btn-text) {
  color: #ffffff;
  background-color: #0bb783;
  border-color: #0bb783;
}
</style>
