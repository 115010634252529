<template>
  <div id="department-detail">
    <div class="subheader subheader-transparent" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ $tc("tables.department") }}
            </h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <div class="d-flex align-items-center">
          <b-button
            tag="a"
            @click="$router.push({ name: 'Departments' })"
            size="sm"
            pill
            variant="secondary"
            class="font-weight-bolder mr-2 text-uppercase"
          >
            <div class="d-flex">
              <i class="fa fa-chevron-left" />
              <span style=" white-space: nowrap">
                {{ $t("generic.buttons.back") }}
              </span>
            </div>
          </b-button>
        </div>
        <!--end::Info-->
      </div>
    </div>
    <b-row>
      <b-col lg="3">
        <b-card
          class="card-custom card-rounded gutter mt-6"
          style="border-radius: 2rem"
        >
          <!--begin::Wrapper-->
          <div class="d-flex justify-content-between flex-column mb-4">
            <!--begin::Container-->
            <div class="">
              <!--begin::Header-->
              <div class="d-flex flex-column flex-center mb-5">
                <!--begin::Symbol-->
                <div
                  class="symbol symbol-120 symbol-circle symbol-success overflow-hidden"
                >
                  <span class="symbol-label">
                    {{ getInitials(department) }}
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Username-->
                <a
                  href="#"
                  class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1"
                  >{{ department.name }}</a
                >
                <!--end::Username-->
                <!--begin::Info-->
                <!-- <div class="font-weight-bold text-dark-50 font-size-sm pb-6">Grade 8, AE3 Student</div> -->
                <!--end::Info-->
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="pt-1">
                <!--begin::Item-->
                <div
                  v-if="hasModule(['risk'])"
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.insider") }}
                  </span>
                  <span
                    class="font-weight-bolder label label-xl label-inline px-3 py-5 min-w-70px base-full"
                    >{{
                      department.insider
                        ? $t(
                            `tables.dimensionIds.dimension.${department.insider}.DEFAULT.title`
                          )
                        : "-"
                    }}</span
                  >
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div
                  v-if="hasModule(['risk'])"
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.risk") }}
                  </span>
                  <span
                    :class="
                      'font-weight-bolder label label-xl label-inline px-3 py-5 min-w-70px ' +
                        variantByValue(department.risk, 6, true, true)
                    "
                    >{{ department.risk || "?" }} / 6</span
                  >
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div
                  v-if="hasModule(['impact'])"
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.hazard") }}
                  </span>
                  <span
                    :class="
                      'font-weight-bolder label label-xl label-inline px-3 py-5 min-w-70px ' +
                        variantByValue(department.hazard, 3, true, true)
                    "
                    >{{ department.hazard || "?" }} / 3</span
                  >
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div
                  v-if="hasModule(['awareness'])"
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.cyber") }}
                  </span>
                  <span
                    :class="
                      'font-weight-bolder label label-xl label-inline px-3 py-5 min-w-70px ' +
                        variantByValue(department.cyber, 10, true, true)
                    "
                    >{{ department.cyber ? $n(department.cyber) : "?" }} /
                    10</span
                  >
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div
                  v-if="hasModule(['wellbeing'])"
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.wellbeing") }}
                  </span>
                  <span
                    :class="
                      'font-weight-bolder label label-xl label-inline px-3 py-5 min-w-70px ' +
                        variantByValue(department.wellbeing, 10, true, true)
                    "
                    >{{
                      department.wellbeing ? $n(department.wellbeing) : "?"
                    }}
                    / 10</span
                  >
                </div>
                <!--end::Item-->
                <!--begin::Item-->
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <span class="text-dark-75 font-weight-bolder mr-2">
                    {{ $t("tables.employees") }}
                  </span>
                  <span
                    v-b-tooltip.hover.bottom="$t('tables.users')"
                    variant="info"
                    class="font-weight-bolder label label-xl label-inline px-3 py-5 min-w-50px"
                    :style="{ cursor: 'pointer' }"
                    @click="
                      $router.push({
                        name: 'Users',
                        query: { department: department.stakeholderId }
                      })
                    "
                  >
                    {{ !isNaN(department.users) ? $n(department.users) : "" }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 12s2.455-6 9-6c4.91 0 9 6 9 6s-4.09 6-9 6c-6.545 0-9-6-9-6z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M12 15a3 3 0 110-6 3 3 0 010 6z" fill="#000" opacity=".3"/></g></svg>
                  </span>
                </div>
                <!--end::Item-->
              </div>
              <!--end::Body-->
            </div>
            <!--eng::Container-->
          </div>
          <!--end::Wrapper-->
        </b-card>
        <div class="mt-6">
          <breaches-progress-pwd
            v-if="selected === 'abs'"
            :data="department.abs"
            type="department"
          />
          <breaches-progress-no-pwd
            v-if="selected === 'abs'"
            :data="department.abs"
            type="department"
          />
        </div>
      </b-col>
      <b-col lg="9">
        <box-tabs :tabs="tabs" @changeTab="changeModule" />
        <team-recommendations
          v-if="selected === 'recommendations'"
          :stakeholderId="$route.params.id"
          :users="parseInt(department.users)"
        />
        <awareness
          v-if="selected === 'cyber'"
          :stakeholderId="$route.params.id"
          :interactions="interactions"
        />
        <security
          v-if="selected === 'security'"
          :stakeholderId="$route.params.id"
          :interactions="interactions"
          :insiderId="department.insider"
        />
        <phishing
          v-if="selected === 'phishing'"
          :stakeholderId="$route.params.id"
          :interactions="campaigns"
          :department="department"
          :users="parseInt(department.users)"
        />
        <latest-breaches v-if="selected === 'abs'" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getStakeholder } from "@/api/controller/stakeholders.api";
import { getProfile, getInteractions } from "@/api/controller/traits.api";
import { getStakeholderCampaigns } from "@/api/controller/campaigns.api";
import {
  extractValueFromProfile,
  getClassByValueAndBase,
  getColorByValueAndBase
} from "@/utils/helpers";

import _ from "lodash";
import axios from "axios";
import { mapGetters } from "vuex";
import VueScrollTo from "vue-scrollto";

import BreachesProgressPwd from "@/components/dashboard/breaches-progress/BreachesProgressPwd.component";
import BreachesProgressNoPwd from "@/components/dashboard/breaches-progress/BreachesProgressNoPwd.component";
import TeamRecommendations from "@/components/departments/TeamRecommendations.component";
import Awareness from "@/components/departments/Awareness.component";
import Security from "@/components/departments/Security.component";
import Phishing from "@/components/departments/Phishing.component";
import LatestBreaches from "@/components/dashboard/latest-breaches-department/LatestBreaches.component";

import BoxTabs from "@/components/menu/BoxTabs.component";

export default {
  name: "DepartmentDetail",
  data() {
    return {
      users: [],
      department: {},
      selected: "",
      interactions: [],
      campaigns: [],
      breaches: []
    };
  },
  components: {
    TeamRecommendations,
    Awareness,
    Security,
    Phishing,
    BoxTabs,
    LatestBreaches,
    BreachesProgressPwd,
    BreachesProgressNoPwd
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["hasModule"]),
    tabs() {
      var result = [
        {
          id: "cyber",
          title: this.$t("tables.cyber"),
          image: "shield-protected.svg",
          service: ["awareness"]
        },
        {
          id: "security",
          title: this.$t("tables.security"),
          image: "mail-locked.svg",
          service: ["risk", "impact"]
        },
        {
          id: "recommendations",
          title: this.$t("tables.recommendations"),
          image: "book-open.svg",
          service: ["archetype"]
        },
        {
          id: "phishing",
          title: this.$t("tables.phishing"),
          image: "fish.svg",
          service: ["phishing"]
        },
        {
          id: "abs",
          title: this.$t("tables.abs.title"),
          image: "key.svg",
          service: ["abs"]
        }
      ];
      return _.filter(result, item => {
        return item.service === undefined || this.hasModule(item.service);
      });
    }
  },
  methods: {
    variantByValue(value, base = 100, color = true, bg = true) {
      return getClassByValueAndBase(value, base, color, bg);
    },
    changeModule(mod) {
      this.selected = mod;
      this.$router.push(
        _.merge(this.$route.params, { hash: `#${this.selected}` })
      );
      VueScrollTo.scrollTo(`.card-header.card-header-tabs-line`, 1500);
      return mod;
    },
    getInitials(department) {
      var initials = "";
      _.each(_.words(department.name), word => {
        initials = initials + word.substring(0, 1);
      });
      return initials;
    },
    getHexByValue(value, base) {
      return getColorByValueAndBase(value, base);
    },
    loadDepartment() {
      const companyStakeholderId = localStorage.getItem("managedCompanyId");

      // DIMENSION_ELEMENT_BREACH_TOTAL
      var profileBasicParams = {
        surveyTypes: [
          this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_SCORE_GLOBAL"),
          this.getConstant("SURVEY_KYMATIO_BREACH_CORPORATE")
        ],
        groupDimensions: [
          this.getConstant("DIMENSION_GROUP_SCORING"),
          this.getConstant("DIMENSION_GROUP_INSIDER"),
          this.getConstant("DIMENSION_GROUP_RISK"),
          this.getConstant("DIMENSION_GROUP_HAZARD"),
          this.getConstant("DIMENSION_GROUP_WORKING"),
          this.getConstant("DIMENSION_GROUP_CIBERSECURITY"),
          this.getConstant("DIMENSION_GROUP_ABS")
        ],
        dimensions: [
          this.getConstant("DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"),
          this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL"),
          this.getConstant("DIMENSION_ELEMENT_GENERAL_RISK"),
          this.getConstant("DIMENSION_ELEMENT_GENERAL_HAZARD"),
          this.getConstant("DIMENSION_ELEMENT_PRINCIPAL_INSIDER"),
          this.getConstant("DIMENSION_ELEMENT_CIBER_RESULTS"),
          this.getConstant("DIMENSION_ELEMENT_BREACH_TOTAL")
        ]
      };
      axios
        .all([
          getStakeholder("departments", this.$route.params.id, {
            stakeholderDepartmentName: true,
            email: true,
            avatar: true,
            stakeholders: true
          }),
          getInteractions(this.$route.params.id, true),
          getProfile(this.$route.params.id, profileBasicParams),
          getStakeholderCampaigns(companyStakeholderId, this.$route.params.id)
        ])
        .then(
          axios.spread(
            (stkResp, interactionsResp, profileResp, campaignsResp) => {
              this.interactions = interactionsResp.data.records;
              this.campaigns = campaignsResp.data.records;
              const stakeholder = stkResp.data.records;
              this.department = {
                stakeholderId: this.$route.params.id,
                name: stakeholder.name,
                users: stakeholder.peopleCount,
                risk: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_RISK",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_GENERAL_RISK"
                ),
                hazard: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_HAZARD",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_GENERAL_HAZARD"
                ),
                cyber: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_CIBERSECURITY",
                  "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_CIBER_RESULTS"
                ),
                wellbeing: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_WORKING",
                  "SURVEY_KYMATIO_CLIMATE_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_CLIMATE_LEVEL"
                ),
                insider: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_INSIDER",
                  "SURVEY_KYMATIO_GRI_GLOBAL",
                  "dimensionValue",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_PRINCIPAL_INSIDER"
                ),
                abs: extractValueFromProfile(
                  profileResp.data.records,
                  "DIMENSION_GROUP_ABS",
                  "SURVEY_KYMATIO_BREACH_CORPORATE",
                  "dimensionValueExtra",
                  interactionsResp.data.records,
                  "DIMENSION_ELEMENT_BREACH_TOTAL"
                )
              };
              this.selected =
                _.trimStart(this.$route.hash, "#") || _.first(this.tabs).id;
            }
          )
        );
    }
  },
  mounted() {
    if (this.isConstantsActive) this.loadDepartment();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadDepartment();
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-mod:after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  background: #c0c9d0;
  width: 20%;
  // left: 100%;
  bottom: calc(0% - 10px);
}
</style>
