<template>
  <div v-if="Object.keys(recommendations).length > 0">
    <b-row align-h="center" class="kanban-container mx-0">
      <b-col
        v-for="(group, dimensionGroupId) in orderedRecommendations"
        :key="dimensionGroupId"
        :lg="6"
        :class="{
          'pr-0': dimensionGroupId % 2 != 0,
          'pl-0': dimensionGroupId % 2 == 0
        }"
      >
        <div class="kanban-board">
          <header class="kanban-board-header light-dark">
            <div class="kanban-title-board py-3">
              {{ $t(`tables.dimensionGroups.${keyByObject(group)}`) }}
            </div>
          </header>
          <main class="kanban-drag p-8">
            <div
              class="kanban-item px-8 py-4 bg-white mb-4"
              :style="{
                cursor: 'default',
                marginLeft: 'auto',
                marginRight: 'auto'
              }"
              v-for="(dimension, dimensionId) in group"
              :key="dimensionId"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column text-center">
                  <div class="text-dark-75 font-size-h6 font-weight-bold mb-1">
                    {{
                      $t(
                        `tables.dimensionIds.dimension.${dimensionId}.DEFAULT.title`
                      )
                    }}
                  </div>
                </div>
              </div>
              <div v-for="(action, actionId) in dimension" :key="actionId">
                <div
                  v-for="(count, actionId) in action"
                  :key="actionId"
                  class="mt-4 d-flex flex-fill justify-content-between"
                >
                  <div
                    class="d-flex flex-column"
                    v-html="getTextByAction(actionId).executeAction.title"
                  />
                  <div class="d-flex flex-column flex-fill ml-7 max-w-150px">
                    <div
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <span
                        class="text-muted mr-2 font-size-sm font-weight-bold"
                        >{{ $n(count / users, "percent") }}</span
                      >
                      <b-icon-info-circle-fill
                        icon="info-circle-fill"
                        scale="1"
                        variant="warning"
                        :id="`info-${actionId}`"
                        v-b-tooltip.v-dark.hover.html="
                          getTextByAction(actionId).executeAction.description
                        "
                      />
                    </div>
                    <div class="progress progress-xs">
                      <b-progress-bar
                        :value="(count / users) * 100"
                        :max="100"
                        :variant="'info'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </b-col>
    </b-row>
  </div>
  <b-row v-else>
    <b-col>
      <empty-card type="department.recommendations" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import {
  getStakeholdersActions,
  getActionsTexts
} from "@/api/controller/traits.api";

import { BIconInfoCircleFill } from "bootstrap-vue";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "TeamRecommendations",
  props: {
    stakeholderId: {
      type: Number,
      required: true
    },
    users: {
      type: Number,
      required: true
    }
  },
  components: {
    BIconInfoCircleFill,
    EmptyCard
  },
  data() {
    return {
      loaded: false,
      recommendations: [],
      texts: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    orderedRecommendations() {
      return _.orderBy(
        this.recommendations,
        [element => Object.keys(element).length],
        ["desc"]
      );
    }
  },
  methods: {
    keyByObject(object) {
      return _.findKey(this.recommendations, object);
    },
    getTextByAction(action) {
      return _.find(this.texts, text => {
        return parseInt(action) === parseInt(text.actionId);
      });
    }
  },
  mounted() {
    var dimensionGroups = [
      this.getConstant("DIMENSION_GROUP_DEPT_REC_TRAINING"),
      this.getConstant("DIMENSION_GROUP_DEPT_REC_UNCERTAINTY"),
      this.getConstant("DIMENSION_GROUP_DEPT_REC_WORK"),
      this.getConstant("DIMENSION_GROUP_DEPT_REC_COMMUNICATION")
    ];

    var securityParams = {
      groupDimensions: dimensionGroups,
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      addDimensions: "YES"
    };

    var result = {};

    getStakeholdersActions(
      "departments",
      "people",
      this.stakeholderId,
      securityParams
    ).then(actionsResp => {
      var actionsIds = _.keys(
        _.groupBy(actionsResp.data.records, action => action.actionId)
      );
      getActionsTexts({ actionsId: actionsIds }).then(textResponse => {
        this.texts = textResponse.data.records;
        _.forEach(dimensionGroups, element => {
          var dGroup = {};

          var byDimGroup = _.filter(actionsResp.data.records, group => {
            return parseInt(group.dimensionGroupId) === parseInt(element);
          });
          dGroup = _.groupBy(byDimGroup, dimElement => {
            return dimElement.dimensionId;
          });

          _.each(_.keys(dGroup), group => {
            var auxElement = dGroup[group];
            if (!result[element]) result[element] = {};
            if (!result[element][group]) result[element][group] = {};
            result[element][group].actions = _.countBy(
              auxElement,
              action => action.actionId
            );
          });
        });
        _.forEach(result, (element, key) => {
          if (element.length < 1 || _.keys(element).length < 1) {
            delete result[key];
          }
        });
        this.recommendations = result;
        this.loaded = true;
      });
    });
  }
};
</script>

<style scoped>
.row {
  margin: 0 !important;
}
</style>
