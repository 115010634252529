<template>
  <b-row v-if="loaded">
    <b-col
      col
      xl="6"
      lg="6"
      md="12"
      sm="12"
      xs="12"
      class="mt-1"
      v-if="hasModule(['risk']) && security.risk.series.length > 0"
    >
      <chart
        :title="
          $t('tables.detail.user.evolution', {
            module: $t('tables.risk')
          })
        "
        :series="security.risk.series"
        :categories="security.risk.labels"
        :dates="security.risk.dates"
        :discrete="security.risk.discrete"
        :base="6"
        height="150px"
        :opacity="true"
        :colors="{
          light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
          normal: ['#855840', '#e6b375', '#beb09f']
        }"
      />
    </b-col>
    <b-col
      col
      xl="6"
      lg="6"
      md="12"
      sm="12"
      xs="12"
      class="mt-1"
      v-if="hasModule(['impact']) && security.hazard.labels.length > 0"
    >
      <chart
        :title="$t('tables.hazard')"
        :series="security.hazard.series"
        :categories="security.hazard.labels"
        :dates="security.hazard.dates"
        :discrete="security.hazard.discrete"
        :base="3"
        height="150px"
        :opacity="true"
        :colors="{
          light: ['#8558409b', '#e6b3759b', '#beb09f9b'],
          normal: ['#855840', '#e6b375', '#beb09f']
        }"
      />
    </b-col>
    <b-col
      col
      cols="auto"
      class="mt-1"
      v-if="hasModule(['risk']) && actions.length > 0"
    >
      <tabs :tabs="actions" />
    </b-col>
    <b-col
      v-if="
        security.risk.labels.length === 0 &&
          security.hazard.labels.length === 0 &&
          actions.length === 0
      "
    >
      <empty-card type="department.security" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";

import { extractValueFromProfile, calcEvolution } from "@/utils/helpers";
import { getProfile, getStakeholderActions } from "@/api/controller/traits.api";

import Chart from "@/components/users/Chart.component";
import Tabs from "@/components/users/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Security",
  props: {
    stakeholderId: {
      type: Number,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    },
    insiderId: {
      required: true
    }
  },
  components: {
    Chart,
    Tabs,
    EmptyCard
  },
  data() {
    return {
      security: {
        risk: {
          series: [],
          labels: [],
          dates: [],
          discrete: []
        },
        hazard: {
          series: [],
          labels: [],
          dates: [],
          discrete: []
        }
      },
      profile: {},
      actions: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Auth", ["hasModule"])
  },
  mounted() {
    var securityParams = {
      groupDimensions: [
        this.getConstant("DIMENSION_GROUP_RISK"),
        this.getConstant("DIMENSION_GROUP_HAZARD")
      ],
      maxValue: "NO",
      dateFrom: "1970-01-01",
      dateTo: "now"
    };

    var securityActionParams = {
      surveyTypes: [
        this.getConstant("SURVEY_KYMATIO_GRI_GLOBAL"),
        this.getConstant("SURVEY_KYMATIO_CLIMATE_GLOBAL")
      ],
      dimensions: [this.insiderId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_INSIDER")],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      entityType: this.getConstant("ENTITY_TYPE_TEAM")
    };
    axios
      .all([
        getProfile(this.stakeholderId, securityParams),
        getStakeholderActions(this.stakeholderId, securityActionParams)
      ])
      .then(
        axios.spread((securityProfileResp, actionsResp) => {
          /* BEGIN-ACTIONS */
          this.actions = actionsResp.data.records;
          /* END-ACTIONS */

          var profile = securityProfileResp.data.records;

          /* BEGIN-RISK */
          this.security.risk.average = extractValueFromProfile(
            securityProfileResp.data.records,
            "DIMENSION_GROUP_RISK",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_GENERAL_RISK"
          );

          this.security.risk.series = [
            { name: this.$t("tables.availability"), data: [] },
            { name: this.$t("tables.integrity"), data: [] },
            { name: this.$t("tables.confidentiality"), data: [] }
          ];
          var availabilityRiskEvolution = calcEvolution(
            "DIMENSION_GROUP_RISK",
            "DIMENSION_ELEMENT_AVAILABILITY_RISK",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.risk.series[0].data = _.map(
            availabilityRiskEvolution.series,
            "value"
          );
          this.security.risk.labels = _.map(
            availabilityRiskEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          var integrityRiskEvolution = calcEvolution(
            "DIMENSION_GROUP_RISK",
            "DIMENSION_ELEMENT_INTEGRITY_RISK",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.risk.series[1].data = _.map(
            integrityRiskEvolution.series,
            "value"
          );
          this.security.risk.labels = _.map(
            integrityRiskEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          var confidentialityRiskEvolution = calcEvolution(
            "DIMENSION_GROUP_RISK",
            "DIMENSION_ELEMENT_CONFIDENTIALITY_RISK",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.risk.series[2].data = _.map(
            confidentialityRiskEvolution.series,
            "value"
          );
          this.security.risk.labels = _.map(
            confidentialityRiskEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          this.security.risk.dates = this.security.risk.labels;
          this.security.risk.discrete = availabilityRiskEvolution.discrete;
          /* END-RISK */
          /* HAZARD */
          this.security.hazard.series = [
            { name: this.$t("tables.availability"), data: [] },
            { name: this.$t("tables.integrity"), data: [] },
            { name: this.$t("tables.confidentiality"), data: [] }
          ];
          var availabilityEvolution = calcEvolution(
            "DIMENSION_GROUP_HAZARD",
            "DIMENSION_ELEMENT_AVAILABILITY_HAZARD",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.hazard.series[0].data = _.map(
            availabilityEvolution.series,
            "value"
          );
          this.security.hazard.labels = _.map(
            availabilityEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          var integrityEvolution = calcEvolution(
            "DIMENSION_GROUP_HAZARD",
            "DIMENSION_ELEMENT_INTEGRITY_HAZARD",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.hazard.series[1].data = _.map(
            integrityEvolution.series,
            "value"
          );
          this.security.hazard.labels = _.map(
            integrityEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          var confidentialityEvolution = calcEvolution(
            "DIMENSION_GROUP_HAZARD",
            "DIMENSION_ELEMENT_CONFIDENTIALITY_HAZARD",
            "SURVEY_KYMATIO_GRI_GLOBAL",
            this.interactions,
            profile
          );
          this.security.hazard.series[2].data = _.map(
            confidentialityEvolution.series,
            "value"
          );
          this.security.hazard.labels = _.map(
            confidentialityEvolution.series,
            point => {
              return moment(point.date, "YYYY-MM-DD hh:mm:ss").format(
                "DD MMM YY"
              );
            }
          );
          this.security.hazard.dates = this.security.hazard.labels;
          this.security.hazard.discrete = availabilityEvolution.discrete;
          this.loaded = true;
        })
      );
  }
};
</script>
