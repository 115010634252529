var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('b-row',[(_vm.cyber.series.length > 0)?_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('chart',{attrs:{"title":_vm.$t('tables.detail.user.evolution', {
          module: _vm.$t('generic.modules.cybersecurity.title')
        }),"series":[
        {
          name: _vm.$t('generic.modules.cybersecurity.title'),
          data: _vm.cyber.series
        }
      ],"categories":_vm.cyber.labels,"discrete":_vm.cyber.discrete,"colors":{
        light: ['#e0c3d5'],
        normal: ['#ce93b8']
      },"height":"380"}})],1):_vm._e(),(_vm.checkCybersecurity)?_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('cyber-chart',{attrs:{"cybersecurity":_vm.cybersecurity,"subtitle":false}})],1):_vm._e(),(_vm.cyber.series.length === 0 && !_vm.checkCybersecurity)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('empty-card',{attrs:{"type":"department.cybersecurity"}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }