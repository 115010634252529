<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div class="card-title text-left">
        <div class="card-label font-weight-bolder text-dark-75">
          {{ $t("dashboard.cybersecurity.chart") }}
          <div class="font-size-sm text-muted mt-2" v-if="subtitle">
            {{ $t("dashboard.cybersecurity.subtitle") }}
          </div>
        </div>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <!--begin::Chart-->
      <apexchart
        class="mt-4"
        type="radar"
        height="260"
        :options="options"
        :series="series"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "CyberChart",
  props: {
    cybersecurity: {
      required: true
    },
    subtitle: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    options() {
      var self = this;
      return {
        chart: {
          height: 260,
          type: "radar",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: value => self.$n(value / 10, "percent")
        },
        plotOptions: {
          radar: {
            size: 100,
            polygons: {
              strokeColors: "#f5f5f5",
              strokeWidth: 1,
              connectorColors: "#f0f0f0",
              fill: {
                colors: ["transparent"]
              }
            }
          }
        },
        colors: ["#ea79c1"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#ea79c1",
          strokeWidth: 2
        },
        tooltip: {
          enabled: true
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: _.map(this.cybersecurity.data, e => {
            return this.$t(
              `tables.dimensionIds.dimension.${this.getConstant(
                e.id
              )}.DEFAULT.title`
            );
          }),
          labels: {
            style: {
              colors: [
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F",
                "#464E5F"
              ],
              fontSize: "13px",
              fontFamily: "Raleway, Helvetica, sans-serif",
              cssClass: "text-dark-75"
            }
          }
        },
        yaxis: {
          max: 10,
          min: 0,
          show: false
        }
      };
    },
    series() {
      return [
        {
          name: this.$t("dashboard.cybersecurity.title"),
          data: _.map(this.cybersecurity.data, e => e.value || 0)
        }
      ];
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.apexcharts-legend.position-left {
  text-align: left;
}
.apexcharts-datalabel {
  color: #464e5f;
}
</style>
