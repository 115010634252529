var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.recommendations).length > 0)?_c('div',[_c('b-row',{staticClass:"kanban-container mx-0",attrs:{"align-h":"center"}},_vm._l((_vm.orderedRecommendations),function(group,dimensionGroupId){return _c('b-col',{key:dimensionGroupId,class:{
        'pr-0': dimensionGroupId % 2 != 0,
        'pl-0': dimensionGroupId % 2 == 0
      },attrs:{"lg":6}},[_c('div',{staticClass:"kanban-board"},[_c('header',{staticClass:"kanban-board-header light-dark"},[_c('div',{staticClass:"kanban-title-board py-3"},[_vm._v(" "+_vm._s(_vm.$t(`tables.dimensionGroups.${_vm.keyByObject(group)}`))+" ")])]),_c('main',{staticClass:"kanban-drag p-8"},_vm._l((group),function(dimension,dimensionId){return _c('div',{key:dimensionId,staticClass:"kanban-item px-8 py-4 bg-white mb-4",style:({
              cursor: 'default',
              marginLeft: 'auto',
              marginRight: 'auto'
            })},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column text-center"},[_c('div',{staticClass:"text-dark-75 font-size-h6 font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.$t( `tables.dimensionIds.dimension.${dimensionId}.DEFAULT.title` ))+" ")])])]),_vm._l((dimension),function(action,actionId){return _c('div',{key:actionId},_vm._l((action),function(count,actionId){return _c('div',{key:actionId,staticClass:"mt-4 d-flex flex-fill justify-content-between"},[_c('div',{staticClass:"d-flex flex-column",domProps:{"innerHTML":_vm._s(_vm.getTextByAction(actionId).executeAction.title)}}),_c('div',{staticClass:"d-flex flex-column flex-fill ml-7 max-w-150px"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-2"},[_c('span',{staticClass:"text-muted mr-2 font-size-sm font-weight-bold"},[_vm._v(_vm._s(_vm.$n(count / _vm.users, "percent")))]),_c('b-icon-info-circle-fill',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-dark.hover.html",value:(
                        _vm.getTextByAction(actionId).executeAction.description
                      ),expression:"\n                        getTextByAction(actionId).executeAction.description\n                      ",modifiers:{"v-dark":true,"hover":true,"html":true}}],attrs:{"icon":"info-circle-fill","scale":"1","variant":"warning","id":`info-${actionId}`}})],1),_c('div',{staticClass:"progress progress-xs"},[_c('b-progress-bar',{attrs:{"value":(count / _vm.users) * 100,"max":100,"variant":'info'}})],1)])])}),0)})],2)}),0)])])}),1)],1):_c('b-row',[_c('b-col',[_c('empty-card',{attrs:{"type":"department.recommendations"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }