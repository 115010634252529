<template>
  <b-row v-if="loaded">
    <b-col v-if="cyber.series.length > 0" lg="6" md="12" sm="12">
      <chart
        :title="
          $t('tables.detail.user.evolution', {
            module: $t('generic.modules.cybersecurity.title')
          })
        "
        :series="[
          {
            name: $t('generic.modules.cybersecurity.title'),
            data: cyber.series
          }
        ]"
        :categories="cyber.labels"
        :discrete="cyber.discrete"
        :colors="{
          light: ['#e0c3d5'],
          normal: ['#ce93b8']
        }"
        height="380"
      />
    </b-col>
    <b-col v-if="checkCybersecurity" lg="6" md="12" sm="12">
      <cyber-chart :cybersecurity="cybersecurity" :subtitle="false" />
    </b-col>
    <b-col
      v-if="cyber.series.length === 0 && !checkCybersecurity"
      md="6"
      sm="12"
    >
      <empty-card type="department.cybersecurity" />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";

import { extractValueFromProfile, calcEvolution } from "@/utils/helpers";
import { getProfile } from "@/api/controller/traits.api";

import Chart from "@/components/users/Chart.component";
import CyberChart from "@/components/dashboard/CyberChart.component";
import EmptyCard from "@/components/EmptyCard.component";

export default {
  name: "Awareness",
  props: {
    stakeholderId: {
      type: String,
      required: true
    },
    interactions: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart,
    CyberChart,
    EmptyCard
  },
  data() {
    return {
      cyber: {},
      cybersecurity: {
        data: []
      },
      profile: {},
      actions: [],
      loaded: false
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    checkCybersecurity() {
      var result = false;
      _.each(this.cybersecurity.data, cyber => {
        if (cyber.value !== false) result = true;
      });
      return result;
    }
  },
  mounted() {
    var cyberProfile = {
      surveyTypes: [this.getConstant("SURVEY_KYMATIO_CYBERSECURITY_GLOBAL")],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CIBERSECURITY")],
      dateFrom: "1970-01-01",
      dateTo: "now"
    };
    axios.all([getProfile(this.stakeholderId, cyberProfile)]).then(
      axios.spread(cyberProfileResp => {
        var evolution = calcEvolution(
          "DIMENSION_GROUP_CIBERSECURITY",
          "DIMENSION_ELEMENT_CIBER_RESULTS",
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
          this.interactions,
          cyberProfileResp.data.records
        );
        this.cyber.series = _.map(evolution.series, "value");
        this.cyber.labels = _.map(evolution.series, point => {
          return moment(point.date).isValid()
            ? moment(point.date, "YYYY-MM-DD hh:mm:ss").format("DD MMM YY")
            : "";
        });
        this.cyber.dates = this.cyber.labels;
        this.cyber.discrete = evolution.discrete;

        this.cybersecurity.data = [
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_DATAPROTECTION"
            ),
            id: "DIMENSION_ELEMENT_DATAPROTECTION",
            name: "dataProtection"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_COMMUNICATIONS"
            ),
            id: "DIMENSION_ELEMENT_COMMUNICATIONS",
            name: "communications"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_MALWARE"
            ),
            id: "DIMENSION_ELEMENT_MALWARE",
            name: "malware"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_PASSWORDMANAGEMENT"
            ),
            id: "DIMENSION_ELEMENT_PASSWORDMANAGEMENT",
            name: "passwordManagement"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_WORKPLACE"
            ),
            id: "DIMENSION_ELEMENT_WORKPLACE",
            name: "workplace"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_COMPLIANCE"
            ),
            id: "DIMENSION_ELEMENT_COMPLIANCE",
            name: "compliance"
          },
          {
            value: extractValueFromProfile(
              cyberProfileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_SOCIALENGINEERING"
            ),
            id: "DIMENSION_ELEMENT_SOCIALENGINEERING",
            name: "socialEngineering"
          }
        ];
        this.loaded = true;
      })
    );
  }
};
</script>
