<template>
  <div v-if="data.length">
    <b-table
      id="latest-breaches-users-department-table"
      hover
      :fields="fields"
      :items="data"
      responsive="sm"
      stacked="sm"
      :per-page="perPage"
      :current-page="currentPage"
      small
    >
      <template #cell(name)="row">
        {{ row.item.user.name + " " + row.item.user.surname }}
      </template>
      <template #cell(breachesPwd)="row">
        {{ row.item.pwdSolved }} / {{ row.item.pwd }}
      </template>
      <template #cell(breachesNoPwd)="row">
        {{ row.item.noPwdSolved }} / {{ row.item.noPwd }}
      </template>
      <template #cell(resolved)="row">
        <b-icon-check
          variant="success"
          scale="2"
          v-if="completed(row.item)"
        ></b-icon-check>
        <b-icon-exclamation-triangle
          scale="2"
          variant="danger"
          v-else
        ></b-icon-exclamation-triangle>
      </template>
      <template #cell(actions)="row">
        <b-button
          pill
          variant="light-primary"
          class="mr-2 px-auto"
          @click="viewDetail(row.item.stakeholderId)"
        >
          <i
            class="la la-eye pl-1"
            v-b-tooltip.right.hover
            :title="$t('tables.seeDetail')"
          />
        </b-button>
      </template>
    </b-table>

    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="companies-table"
        first-number
        pill
        last-number
        size="lg"
      ></b-pagination>
    </div>
  </div>
  <div v-else>
    <empty-card ype="department.abs" />
  </div>
</template>

<script>
import _ from "lodash";
import EmptyCard from "@/components/EmptyCard.component";
import { BIconCheck, BIconExclamationTriangle } from "bootstrap-vue";

export default {
  name: "LatestBreachesDepartment",
  data() {
    return {
      perPage: 10,
      currentPage: 1
    };
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    EmptyCard,
    BIconCheck,
    BIconExclamationTriangle
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: this.$tc("tables.name", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "lastBreachDate",
          label: this.$tc("tables.breachDate", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "breachesPwd",
          label: this.$tc("tables.breachespsw", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "breachesNoPwd",
          label: this.$tc("tables.breachesnopsw", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-left"
        },
        {
          key: "resolved",
          label: this.$tc("tables.resolved", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-center",
          // eslint-disable-next-line no-unused-vars
          formatter: (value, key, item) => {
            return this.completed(item);
          }
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
    },
    total() {
      return _.size(this.data);
    }
  },
  methods: {
    viewDetail(stakeholderId) {
      this.$router.push({
        name: "UserDetail",
        params: {
          id: stakeholderId
        }
      });
    },
    completed(item) {
      return item.pwdSolved + item.noPwdSolved - (item.pwd + item.noPwd) == 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.card.card-custom.card-stretch.gutter-b {
  height: auto;
}
.has-badge {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.7rem;
}
.is-link {
  cursor: pointer;
}
</style>
